<style scoped>
.listWorkouts {
  height: 550px;
  overflow: scroll;
}

.sticky {
  position: sticky;
  top: 0px;
}

.listExercices {
  height: 550px;
  overflow: scroll;
}

.containerPartieWorkout {
  height: 800px;
  overflow: scroll;
}

.separator {
  color: #000000;
  height: 3px;
}

.containerPartieWorkout {
  margin-bottom: 10px;
  border-radius: 5px;
}

.cardDetailFood {
  min-height: 600px;
}

.boxlist {
  border-bottom: 1px solid #dfe0e1;
  cursor: pointer;
}

.boxIngredient {
  border: 1px solid #dfe0e1;
  padding: 10px;
  margin-bottom: 5px;
}

.boxlist:hover {
  background-color: #ededed;
}

.smalltext {
  font-size: 10px;
}
</style>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import { Container, Draggable } from "vue-dndrop";
import AsyncError from "@/components/exercice/AsyncError.vue";
import AsyncLoading from "@/components/exercice/AsyncLoading.vue";
const AsyncExercice = import("@/components/exercice/AsyncExercice.vue");

/**
 * Profile component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Container,
    Draggable,
    AsyncComponent: () => ({
      component: AsyncExercice,
      loading: AsyncLoading,
      error: AsyncError,
      delay: 0,
      timeout: 3000,
    }),
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    majuscule: function (value) {
      if (!value) return "";
      return (value = value.toString().toUpperCase());
    },
  },
  page: {
    title: "Basic Workouts",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  mounted() {
    this.getAllBasicWorkouts();
    this.getAllExercices();
    this.getAllVideos();
  },
  data() {
    return {
      title: "Basic Workouts",
      saving_modification: false,
      saving_photo: false,
      adding_workout: false,
      deleting_workout: false,
      transloading_photo: false,
      add_workout: false,
      copy_workout: false,
      exercices: [],
      videos: [],
      forceUpdateList: 1,
      workouts: [],
      workout: null,
      uploadValue: 0,
      typeDrag: "exercices",
      typePartWorkout: [
        "Normal",
        "HIIT",
        "AFAP",
        "Pyramidal",
        "Video",
        "Running",
      ],
      typesWorkout: [
        "VIDEO",
        "VIDEOLIVE",
        "NORMAL",
        "HIIT",
        "PYRAMIDAL",
        "AFAP",
        "CARDIO",
      ],
      muscles: [
        "Legs",
        "Onlyglutei",
        "Abs",
        "Chest",
        "Back",
        "Shoulders",
        "Biceps",
        "Triceps",
        "Cardio",
        "Stretching",
        "Warming up",
        "Fullbody",
      ],
      niveaux: ["Easy", "Medium", "Hard"],
      materiels: [
        "Bodyweight",
        "Dumbbells or Bottles",
        "Kettlebell",
        "Barbell",
        "Jumping rope",
        "Weight plate",
        "Tapis roulant",
        "Elliptical",
        "Stepper",
        "Bike",
        "Stairs",
        "Rower",
        "Fitball",
        "Elastic",
        "Bench",
        "Gym",
        "Medecine Ball",
        "Multifunction",
        "Ankles",
        "TRX",
        "Outdoor",
        "Step",
        "Chair",
      ],
      objectifs: [
        "anticellulite",
        "tonification",
        "brulegraisse",
        "muscle",
        "force",
        "affinertaille",
        "definition",
      ],
      country: [
        "Afghanistan:AF",
        "Albania:AL",
        "Algeria:DZ",
        "Andorra:AD",
        "Angola:AO",
        "Antigua and Barbuda:AG",
        "Argentina:AR",
        "Armenia:AM",
        "Australia:AU",
        "Austria:AT",
        "Azerbaijan:AZ",
        "Bahamas:BS",
        "Bahrain:BH",
        "Bangladesh:BD",
        "Barbados:BB",
        "Belarus:BY",
        "Belgium:BE",
        "Belize:BZ",
        "Benin:BJ",
        "Bhutan:BT",
        "Bolivia (Plurinational State of):BO",
        "Bosnia and Herzegovina:BA",
        "Botswana:BW",
        "Brazil:BR",
        "Brunei Darussalam:BN",
        "Bulgaria:BG",
        "Burkina Faso:BF",
        "Burundi:BI",
        "Cabo Verde:CV",
        "Cambodia:KH",
        "Cameroon:CM",
        "Canada:CA",
        "Central African Republic:CF",
        "Chad:TD",
        "Chile:CL",
        "China:CN",
        "Colombia:CO",
        "Comoros:KM",
        "Congo:CG",
        "Cook Islands:CK",
        "Costa Rica:CR",
        "Croatia:HR",
        "Cuba:CU",
        "Cyprus:CY",
        "Czechia:CZ",
        "Côte d'Ivoire:CI",
        "Democratic People's Republic of Korea:KP",
        "Democratic Republic of the Congo:CD",
        "Denmark:DK",
        "Djibouti:DJ",
        "Dominica:DM",
        "Dominican Republic:DO",
        "Ecuador:EC",
        "Egypt:EG",
        "El Salvador:SV",
        "Equatorial Guinea:GQ",
        "Eritrea:ER",
        "Estonia:EE",
        "Eswatini:SZ",
        "Ethiopia:ET",
        "Faroe Islands:FO",
        "Fiji:FJ",
        "Finland:FI",
        "France:FR",
        "Gabon:GA",
        "Gambia:GM",
        "Georgia:GE",
        "Germany:DE",
        "Ghana:GH",
        "Greece:GR",
        "Grenada:GD",
        "Guatemala:GT",
        "Guinea:GN",
        "Guinea-Bissau:GW",
        "Guyana:GY",
        "Haiti:HT",
        "Honduras:HN",
        "Hungary:HU",
        "Iceland:IS",
        "India:IN",
        "Indonesia:ID",
        "Iran (Islamic Republic of):IR",
        "Iraq:IQ",
        "Ireland:IE",
        "Israel:IL",
        "Italy:IT",
        "Jamaica:JM",
        "Japan:JP",
        "Jordan:JO",
        "Kazakhstan:KZ",
        "Kenya:KE",
        "Kiribati:KI",
        "Kuwait:KW",
        "Kyrgyzstan:KG",
        "Lao People's Democratic Republic:LA",
        "Latvia:LV",
        "Lebanon:LB",
        "Lesotho:LS",
        "Liberia:LR",
        "Libya:LY",
        "Lithuania:LT",
        "Luxembourg:LU",
        "Madagascar:MG",
        "Malawi:MW",
        "Malaysia:MY",
        "Maldives:MV",
        "Mali:ML",
        "Malta:MT",
        "Marshall Islands:MH",
        "Mauritania:MR",
        "Mauritius:MU",
        "Mexico:MX",
        "Micronesia (Federated States of):FM",
        "Monaco:MC",
        "Mongolia:MN",
        "Montenegro:ME",
        "Morocco:MA",
        "Mozambique:MZ",
        "Myanmar:MM",
        "Namibia:NA",
        "Nauru:NR",
        "Nepal:NP",
        "Netherlands:NL",
        "New Zealand:NZ",
        "Nicaragua:NI",
        "Niger:NE",
        "Nigeria:NG",
        "Niue:NU",
        "North Macedonia:MK",
        "Norway:NO",
        "Oman:OM",
        "Pakistan:PK",
        "Palau:PW",
        "Panama:PA",
        "Papua New Guinea:PG",
        "Paraguay:PY",
        "Peru:PE",
        "Philippines:PH",
        "Poland:PL",
        "Portugal:PT",
        "Qatar:QA",
        "Republic of Korea:KR",
        "Republic of Moldova:MD",
        "Romania:RO",
        "Russian Federation:RU",
        "Rwanda:RW",
        "Saint Kitts and Nevis:KN",
        "Saint Lucia:LC",
        "Saint Vincent and the Grenadines:VC",
        "Samoa:WS",
        "San Marino:SM",
        "Sao Tome and Principe:ST",
        "Saudi Arabia:SA",
        "Senegal:SN",
        "Serbia:RS",
        "Seychelles:SC",
        "Sierra Leone:SL",
        "Singapore:SG",
        "Slovakia:SK",
        "Slovenia:SI",
        "Solomon Islands:SB",
        "Somalia:SO",
        "South Africa:ZA",
        "South Sudan:SS",
        "Spain:ES",
        "Sri Lanka:LK",
        "Sudan:SD",
        "Suriname:SR",
        "Sweden:SE",
        "Switzerland:CH",
        "Syrian Arab Republic:SY",
        "Tajikistan:TJ",
        "Thailand:TH",
        "Timor-Leste:TL",
        "Togo:TG",
        "Tokelau:TK",
        "Tonga:TO",
        "Trinidad and Tobago:TT",
        "Tunisia:TN",
        "Turkey:TR",
        "Turkmenistan:TM",
        "Tuvalu:TV",
        "Uganda:UG",
        "Ukraine:UA",
        "United Arab Emirates:AE",
        "United Kingdom of Great Britain and Northern Ireland:GB",
        "United Republic of Tanzania:TZ",
        "United States of America:US",
        "Uruguay:UY",
        "Uzbekistan:UZ",
        "Vanuatu:VU",
        "Venezuela (Bolivarian Republic of):VE",
        "Viet Nam:VN",
        "Yemen:YE",
        "Zambia:ZM",
        "Zimbabwe:ZW",
      ],
      optionsRunning: ["Walk", "Running"],
      optionsTypeObjectifRunning: ["minutes", "km"],
      optionsTypeRepetExo: ["Reps", "Secondes", "Minutes"],
      langues: ["it", "fr", "us", "es", "br"],
      items: [
        {
          text: "Basic Workouts",
        },
        {
          text: "basicworkouts",
          active: true,
        },
      ],
    };
  },
  methods: {
    setTimestampDate() {
      var date = document.getElementById("date").value;
      var timestamp = new Date(date).getTime();
      this.workout.data.date = timestamp;
    },
    timestampToDate(timestamp) {
      return new Date(timestamp).toISOString().substr(0, 10);
    },
    changeTypeDrag(type) {
      this.typeDrag = type;
    },
    onDrop(key, dropResult) {
      if (
        dropResult.element._prevClass == "droppable-item" &&
        dropResult.addedIndex != null
      ) {
        //L'index de l'ingredient (dans le tableau ingredient) qui a été draggé
        var indexItem = dropResult.payload;

        var itemSelect = null;
        if (this.typeDrag == "exercices")
          itemSelect = this.exercices[indexItem];

        if (this.typeDrag == "videos") itemSelect = this.videos[indexItem];

        //Dans quel index du tableau des ingredients de la recette on drop l'élément draggé
        var indexDansWorkout = dropResult.addedIndex;

        console.log(
          ":::" + firebase.firestore().doc(this.typeDrag + "/" + itemSelect.id)
        );

        var newExercice = {
          exercice: firebase
            .firestore()
            .doc(this.typeDrag + "/" + itemSelect.id),
          repetition_exercice: 0,
          type_rep_exercice: "Reps",
          set_exercice: 0,
          recuperation_exercice: 0,
          recuperation_set: 0,
          commentaires: {
            it: null,
            fr: null,
            us: null,
            es: null,
            br: null,
          },
        };

        //On transforme maintenant le JSON d'ingrédient dans la recette en array
        var tabExercicesWorkout = this.workout.data.part_workout[key].exercices;
        tabExercicesWorkout.splice(indexDansWorkout, 0, newExercice);

        //On réinsère les ingredients dans la recette
        this.workout.data.part_workout[key].exercices = tabExercicesWorkout;

        this.forceUpdateList++;
      }
      if (dropResult.element._prevClass == "draggable-item") {
        var anciennePosition = dropResult.removedIndex;
        var newPosition = dropResult.addedIndex;

        var array = this.workout.data.part_workout[key].exercices;

        this.workout.data.part_workout[key].exercices = this.move(
          array,
          anciennePosition,
          newPosition
        );

        this.forceUpdateList++;
      }
    },
    move(input, from, to) {
      let numberOfDeletedElm = 1;

      const elm = input.splice(from, numberOfDeletedElm)[0];

      numberOfDeletedElm = 0;

      input.splice(to, numberOfDeletedElm, elm);

      return input;
    },
    getChildPayload1(id) {
      console.log(id);
      return id;
    },
    getChildPayload2(id) {
      return id;
    },

    showHideCommentaire(key, index) {
      var element = document.getElementById("commentaire-" + key + "-" + index);
      var state = element.style.display;

      if (state == "none") element.style.display = "block";
      else element.style.display = "none";
    },
    deletePhoto() {
      this.workout.data.photo = {
        original: null,
        optimized: null,
        thumbnail: null,
      };
    },
    async transloadPhoto() {
      this.transloading_photo = true;

      var url = this.workout.data.photo.original;

      var data = {
        id: this.workout.id,
        url: url,
        type: "workouts",
      };

      var transloadPhoto = firebase.functions().httpsCallable("transloadPhoto");
      transloadPhoto(data).then((result) => {
        // Read result of the Cloud Function.
        console.log(result);
        this.workout.data.photo = result.data.photo;
        firebase
          .firestore()
          .collection("basicworkouts")
          .doc(data.id)
          .update(this.workout.data);
        this.transloading_photo = false;
      });
    },
    async transferPhoto() {
      this.saving_photo = true;

      var newPhoto = document.getElementById("newPhoto").files[0];
      console.log(newPhoto);
      var type = newPhoto.name.split(".").pop();
      var filename =
        "workouts/" + this.workout.id + "-" + Date.now() + "." + type;

      const storageRef = firebase
        .app()
        .storage("gs://gabriellavicofitness")
        .ref(filename)
        .put(newPhoto);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            var db = firebase.firestore();
            db.collection("basicworkouts")
              .doc(this.workout.id)
              .update({
                photo: {
                  original: url,
                  optimized: null,
                  thumbnail: null,
                },
              })
              .then(() => {
                console.log("ingredient mis a jour");
                this.workout.data.photo = {
                  original: url,
                  optimized: null,
                  thumbnail: null,
                };
                this.saving_photo = false;
                this.uploadValue = 0;
                document.getElementById("newPhoto").value = "";
              })
              .catch((error) => {
                console.error("Error writing document: ", error);
              });
          });
        }
      );
    },
    duplicateWorkout() {
      this.copy_workout = true;
      var db = firebase.firestore();
      var copyworkout = this.workout.data;
      copyworkout.description.it.nom =
        copyworkout.description.it.nom.concat(" (copy)");
      copyworkout.status.br = false;
      copyworkout.status.es = false;
      copyworkout.status.fr = false;
      copyworkout.status.it = false;
      copyworkout.status.us = false;
      console.log(copyworkout);
      db.collection("basicworkouts")
        .add(copyworkout)
        .then(() => {
          this.copy_workout = false;
          this.workouts = [];
          this.workout = null;
          this.getAllBasicWorkouts();
          console.log("Document successfully written!");
        })
    },
    saveModificationsWorkout() {
      this.saving_modification = true;

      var db = firebase.firestore();
      db.collection("basicworkouts")
        .doc(this.workout.id)
        .update(this.workout.data)
        .then(() => {
          console.log("Document successfully written!");
          this.saving_modification = false;
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    },
    addNewWorkout() {
      this.adding_workout = true;
      var db = firebase.firestore();
      db.collection("basicworkouts")
        .doc(this.workout.id)
        .set(this.workout.data)
        .then(() => {
          this.adding_workout = false;
          this.add_workout = false;
          this.workouts = [];
          this.workout = null;
          this.getAllBasicWorkouts();
          console.log("Document successfully written!");
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    },
    getPhotoWorkout(workout) {
      if (workout.data.photo.original != null)
        return workout.data.photo.original;
      else return require("@/assets/images/nophoto.png");
    },
    getPhotoWorkoutThumb(workout) {
      if (workout.data.photo.thumbnail != null)
        return workout.data.photo.thumbnail;
      else return require("@/assets/images/nophoto.png");
    },
    searchWorkout() {
      var search = document.getElementById("searchworkout").value.toLowerCase();
      var category = document.getElementById("searchType").value;
      //filtrer en javascript

      document.querySelectorAll("[data-type='workout']").forEach((element) => {
        var id_workout = element.dataset.id;
        //On récupère le JSON de l'ingredient avec son id
        let workout = this.workouts.find((el) => el.id === id_workout);

        //On regarde si la chaine de caractère est comprise dans le nom d'une des langues pour afficher ou masquer
        if (
          (workout.data.description.it.nom.toLowerCase().includes(search) ||
            workout.data.description.fr.nom.toLowerCase().includes(search) ||
            workout.data.description.us.nom.toLowerCase().includes(search) ||
            search == "") &&
          (workout.data.type_workout == category || category == "all")
        ) {
          console.log("block");
          element.style = "display: block";
        } else {
          console.log("none");
          element.style = "display: none";
        }
      });
    },
    searchVideo() {
      var search = document.getElementById("searchvideo").value.toLowerCase();
      //filtrer en javascript

      document.querySelectorAll("[data-type='video']").forEach((element) => {
        var id_video = element.dataset.id;
        //On récupère le JSON de l'ingredient avec son id
        let video = this.videos.find((el) => el.id === id_video);
        //On regarde si la chaine de caractère est comprise dans le nom d'une des langues pour afficher ou masquer
        if (
          video.data.description.it.nom.toLowerCase().includes(search) ||
          video.data.description.fr.nom.toLowerCase().includes(search) ||
          video.data.description.us.nom.toLowerCase().includes(search) ||
          search == ""
        )
          element.style = "display: block";
        else element.style = "display: none";
      });
    },
    searchExercice() {
      var search = document
        .getElementById("searchexercice")
        .value.toLowerCase();
      var muscle = document.getElementById("searchMuscle").value;
      //filtrer en javascript

      document.querySelectorAll("[data-type='exercice']").forEach((element) => {
        var id_exercice = element.dataset.id;
        //On récupère le JSON de l'ingredient avec son id
        let exercice = this.exercices.find((el) => el.id === id_exercice);

        console.log("exercices:  " + JSON.stringify(exercice));

        //On regarde si la chaine de caractère est comprise dans le nom d'une des langues pour afficher ou masquer
        if (
          (exercice.data.description.it.nom.toLowerCase().includes(search) ||
            exercice.data.description.fr.nom.toLowerCase().includes(search) ||
            exercice.data.description.us.nom.toLowerCase().includes(search) ||
            search == "") &&
          (exercice.data.muscles.includes(muscle) || muscle == "all")
        ) {
          console.log("block");
          element.style = "display: block";
        } else {
          console.log("none");
          element.style = "display: none";
        }
      });
    },
    getAllBasicWorkouts() {
      var db = firebase.firestore();
      db.collection("basicworkouts")
        .orderBy("description.it.nom")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.workouts.push({ id: doc.id, data: doc.data() });
          });
        });
    },
    getAllExercices() {
      var db = firebase.firestore();
      db.collection("exercices")
        .orderBy("description.it.nom")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.exercices.push({ id: doc.id, data: doc.data() });
          });
        });
    },
    getAllVideos() {
      var db = firebase.firestore();
      db.collection("videos")
        .orderBy("description.it.nom")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.videos.push({ id: doc.id, data: doc.data() });
          });
        });
    },
    showExerciceFromRef(refExercice) {
      refExercice.get().then((data) => {
        data;
      });
    },
    async setWorkout(workout) {
      this.add_workout = false;
      this.workout = workout;
    },
    showAddWorkout() {
      var json_workout = {
        photo: {
          original: null,
          optimized: null,
          thumbnail: null,
        },
        description: {
          it: {
            nom: "",
            description: "",
          },
          fr: {
            nom: "",
            description: "",
          },
          us: {
            nom: "",
            description: "",
          },
          es: {
            nom: "",
            description: "",
          },
          br: {
            nom: "",
            description: "",
          },
        },
        part_workout: [],
        status: {
          it: false,
          fr: false,
          us: false,
          es: false,
          br: false,
        },
        date: new Date().toISOString().slice(0, 10),
        niveau_workout: [],
        duree_workout: 0,
        kcal_workout: 0,
        materiel_workout: [],
        muscles_workout: [],
        objectif_workout: [],
        type_workout: "NORMAL",
        country: null,
        lieu: null,
      };

      this.workout = {
        type: "new",
        id: String(Date.now()),
        data: json_workout,
      };

      this.add_workout = true;
    },
    getFlag(key) {
      return (
        "<img src='https://flagcdn.com/h20/" + key + ".png' height='12' />"
      );
    },
    hideModal() {
      this.$refs["delete-modal"].hide();
    },
    showModal() {
      this.$refs["delete-modal"].show();
    },
    deleteWorkout() {
      this.deleting_workout = true;
      var db = firebase.firestore();
      db.collection("basicworkouts")
        .doc(this.workout.id)
        .delete()
        .then(() => {
          this.$refs["delete-modal"].hide();
          this.deleting_workout = false;
          this.add_workout = false;
          this.workouts = [];
          this.workout = null;
          this.getAllBasicWorkouts();
          console.log("Document successfully deleted!");
        })
        .catch((error) => {
          console.error("Error removing document: ", error);
        });
    },
    deleteExerciceList(key, index) {
      this.workout.data.part_workout[key].exercices.splice(index, 1);
      this.forceUpdateList++;
    },
    deletePartWorkout(key) {
      this.workout.data.part_workout.splice(key, 1);
      this.forceUpdateList++;
    },
    addPartWorkout() {
      var newPart = {
        description: {
          it: {
            nom: null,
          },
          fr: {
            nom: null,
          },
          us: {
            nom: null,
          },
          es: {
            nom: null,
          },
          br: {
            nom: null,
          },
        },
        type: "Normal",
        type_hiit: {
          rounds_hiit: 0,
          work_time: 0,
          rest_time_exercice: 0,
          rest_time_round: 0,
        },
        type_pyramidal: {
          start_pyramidal: 0,
          finish_pyramidal: 10,
          oneway: false,
        },
        type_running: {
          type_effort: "Running",
          objectif: 0,
          type_objectif: "minutes",
          objectif_vitesse: null,
        },
        exercices: [],
      };

      this.workout.data.part_workout.push(newPart);
    },
    arraymove(arr, fromIndex, toIndex) {
      var element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <b-modal ref="delete-modal" hide-footer title="Delete workout">
      <div class="d-block text-center">
        <h3>Are you sure ?!</h3>
        <p>This action is irreversible</p>
      </div>
      <div class="d-grid gap-2">
        <b-button
          class="mt-3"
          variant="outline-danger"
          block
          @click="deleteWorkout"
        >
          <span
            v-if="deleting_workout"
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Yes, delete definitely</b-button
        >
        <b-button
          class="mt-2"
          variant="outline-warning"
          block
          @click="hideModal"
          >No, close</b-button
        >
      </div>
    </b-modal>

    <div class="container-fluid">
      <div class="row">
        <div class="col-3 pt-2 pb-0 card" style="min-height: 650px">
          <div class="mb-2">
            <div class="d-grid gap-2">
              <button
                class="btn btn-primary"
                type="button"
                @click="showAddWorkout()"
              >
                + Add Workout
              </button>
            </div>
            <hr />
          </div>
          <div class="mb-0">
            <input
              type="text"
              class="form-control"
              id="searchworkout"
              placeholder="Search workout..."
              @keyup="searchWorkout()"
            />
            <select
              class="form-select mt-2"
              aria-label="Default select example"
              @change="searchWorkout()"
              id="searchType"
            >
              <option value="all" selected>Tous les type</option>
              <option
                v-for="type in typesWorkout"
                :key="'categorysearch' + type"
                :value="type"
              >
                {{ type }}
              </option>
            </select>
            <div class="alert alert-secondary mt-2 px-2 py-1" role="alert">
              <small>{{ workouts.length }} workouts</small>
            </div>
            <div class="listWorkouts">
              <div
                v-for="(workout, key) in workouts"
                :key="'workout' + key"
                :id="'workoutid' + key"
                :data-id="workout.id"
                data-type="workout"
                @click="setWorkout(workout)"
              >
                <div class="container boxlist px-0 py-2">
                  <div class="row">
                    <div class="col-auto">
                      <img
                        width="50px"
                        :src="getPhotoWorkoutThumb(workout)"
                        :key="'photothumb' + workout.id"
                        alt
                        class="img-thumbnail"
                      />
                    </div>
                    <div class="col">
                      <p class="fw-bold mb-0">
                        {{ workout.data.description.it.nom }}
                      </p>
                      <p class="fw-light smalltext mb-0">
                        {{ workout.data.type_workout }} -
                        {{ workout.data.duree_workout }} - {{ workout.id }}
                      </p>
                      <div>
                        <div
                          v-for="(value, name, key) in workout.data.description"
                          :key="'infodescription' + key"
                          class="d-inline p-1"
                        >
                          <img
                            :src="'https://flagcdn.com/h20/' + name + '.png'"
                            height="12"
                            :style="
                              workout.data.description[name].nom == '' ||
                              workout.data.description[name].nom == null
                                ? 'opacity: 0.3'
                                : 'opacity: 1'
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-9">
          <div class="card cardDetailFood">
            <div v-if="workout == null" class="text-center">Select workout</div>
            <div v-if="workout != null" class="p-3">
              <div class="container px-0 py-2">
                <div class="row">
                  <div class="col-12">
                    <div class="container">
                      <div class="row">
                        <div class="col-8">
                          <h1 class="mb-0">
                            {{ workout.data.description.it.nom }}
                          </h1>
                          <small>Workout #{{ workout.id }}</small>
                        </div>
                        <div class="col-4">
                          <div class="d-grid gap-2">
                            <button
                              type="button"
                              class="btn btn-success btn-lg float-end"
                              @click="saveModificationsWorkout()"
                              v-if="workout.type != 'new'"
                            >
                              <span
                                v-if="saving_modification"
                                class="spinner-grow spinner-grow-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              Save modifications
                            </button>
                            <b-button
                              id="show-btn"
                              class="float-end"
                              variant="danger"
                              @click="showModal"
                              v-if="workout.type != 'new'"
                              >Delete</b-button
                            >
                            <b-button
                              id="show-btn"
                              class="float-end"
                              variant="danger"
                              @dblclick="duplicateWorkout()"
                              v-if="workout.type != 'new'"
                            >
                              <span
                                v-if="copy_workout"
                                class="spinner-grow spinner-grow-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              Duplicate</b-button
                            >
                            <button
                              type="button"
                              class="btn btn-success btn-lg float-end"
                              @click="addNewWorkout()"
                              v-if="workout.type == 'new'"
                            >
                              <span
                                v-if="saving_modification"
                                class="spinner-grow spinner-grow-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              Add Workout
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div class="container px-0 py-2">
                      <div class="row">
                        <b-tabs content-class="mt-3">
                          <b-tab
                            v-for="(langue, key) in workout.data.description"
                            :key="'descriptiontab-' + key"
                            :active="key === 'it'"
                          >
                            <template slot="title">
                              <img
                                :src="'https://flagcdn.com/h20/' + key + '.png'"
                                height="12"
                              />
                              {{ key }}
                            </template>

                            <div class="mb-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                :id="'status_' + key"
                                v-model="workout.data.status[key]"
                              />

                              <label :for="'nom_' + key" class="form-label"
                                >Publish ?</label
                              >
                              <input
                                type="text"
                                class="form-control"
                                :id="'workoutnom_' + key"
                                v-model="workout.data.description[key].nom"
                              />
                            </div>
                            <div class="mb-3">
                              <label :for="'workout_' + key" class="form-label"
                                >Workout*</label
                              >
                              <textarea
                                class="form-control"
                                :id="'workoutdescription_' + key"
                                rows="3"
                                v-model="
                                  workout.data.description[key].description
                                "
                              ></textarea>
                            </div>
                          </b-tab>
                        </b-tabs>
                      </div>
                    </div>
                    <hr />
                    <div class="container alert alert-warning">
                      <div class="row">
                        <div class="col-auto">
                          <img
                            width="200px"
                            :src="getPhotoWorkout(workout)"
                            :key="'photo' + workout.id"
                            alt
                            class="img-thumbnail"
                          />
                        </div>
                        <div class="col">
                          <ul>
                            <li>
                              <small
                                >Original:
                                <a
                                  :href="workout.data.photo.original"
                                  target="_blank"
                                  >{{ workout.data.photo.original }}</a
                                ></small
                              >
                            </li>
                            <li>
                              <small
                                >Optimized:
                                <a
                                  :href="workout.data.photo.optimized"
                                  target="_blank"
                                  >{{ workout.data.photo.optimized }}</a
                                ></small
                              >
                            </li>
                            <li>
                              <small
                                >Thumbnail:
                                <a
                                  :href="workout.data.photo.thumbnail"
                                  target="_blank"
                                  >{{ workout.data.photo.thumbnail }}</a
                                ></small
                              >
                            </li>
                          </ul>
                          <div
                            class="d-grid gap-2 mt-2"
                            v-if="workout.type != 'new'"
                          >
                            <label for="formFile" class="form-label"
                              >Change photo:</label
                            >
                            <div class="row">
                              <div class="col">
                                <input
                                  class="form-control"
                                  type="file"
                                  id="newPhoto"
                                />
                                <progress
                                  v-if="saving_photo"
                                  id="progress"
                                  :value="uploadValue"
                                  max="100"
                                ></progress>
                              </div>
                              <div class="col d-grid gap-2">
                                <button
                                  class="btn btn-primary"
                                  type="button"
                                  id="uploadPhoto"
                                  @click="transferPhoto()"
                                >
                                  <span
                                    v-if="saving_photo"
                                    class="spinner-grow spinner-grow-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  Update photo
                                </button>
                                <div class="alert alert-secondary" role="alert">
                                  <i class="fas fa-exclamation-circle"></i>
                                  Dimension 1000x400
                                </div>
                              </div>
                              <div class="col">
                                <div
                                  v-if="workout.data.photo.original != null"
                                  class="d-grid gap-2"
                                >
                                  <button
                                    class="btn btn-info"
                                    type="button"
                                    id="uploadPhoto"
                                    @dblclick="transloadPhoto()"
                                  >
                                    <span
                                      v-if="transloading_photo"
                                      class="spinner-grow spinner-grow-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                    Transload photo
                                  </button>
                                  <button
                                    class="btn btn-danger"
                                    type="button"
                                    id="uploadPhoto"
                                    @dblclick="deletePhoto()"
                                  >
                                    <span
                                      v-if="transloading_photo"
                                      class="spinner-grow spinner-grow-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                    Delete photo
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div>
                      <label for="example-input"
                        >Date de publication (Y-m-d)</label
                      >
                      <input
                        type="date"
                        class="form-control"
                        id="date"
                        :value="timestampToDate(workout.data.date)"
                        @change="setTimestampDate()"
                      />
                    </div>
                    <div v-if="workout.data.type_workout == 'VIDEOLIVE'">
                      <div class="row">
                        <div class="col">
                          <label>Country:</label>
                          <br />
                          <b-form-select
                            v-model="workout.data.country"
                            :options="country"
                            id="country_workout"
                            value-field="type"
                            text-field="type"
                            disabled-field="notEnabled"
                          ></b-form-select>
                        </div>
                        <div class="col">
                          <label>Lieux:</label><br />
                          <b-form-input
                            type="text"
                            v-model="workout.data.lieu"
                            id="lieu_workout"
                            placeholder="Lieu Workout"
                          ></b-form-input>
                        </div>
                      </div>
                    </div>
                    <div class="alert alert-secondary mt-3" role="alert">
                      <div class="container">
                        <div class="row">
                          <div class="col">
                            <b-form-group label="Level:">
                              <b-form-checkbox-group
                                id="level_workout"
                                v-model="workout.data.niveau_workout"
                                :options="niveaux"
                                name="flavour-1"
                              ></b-form-checkbox-group>
                            </b-form-group>
                          </div>
                          <div class="col">
                            <b-form-group label="Materiel:">
                              <b-form-checkbox-group
                                id="materiel_workout"
                                v-model="workout.data.materiel_workout"
                                :options="materiels"
                                name="flavour-1"
                              ></b-form-checkbox-group>
                            </b-form-group>
                          </div>

                          <div class="col">
                            <b-form-group label="Muscles:">
                              <b-form-checkbox-group
                                id="muscles_workout"
                                v-model="workout.data.muscles_workout"
                                :options="muscles"
                                name="flavour-1"
                              ></b-form-checkbox-group>
                            </b-form-group>
                          </div>

                          <div class="col">
                            <b-form-group label="Objectifs:">
                              <b-form-checkbox-group
                                id="objectifs_workout"
                                v-model="workout.data.objectif_workout"
                                :options="objectifs"
                                name="flavour-1"
                              ></b-form-checkbox-group>
                            </b-form-group>
                          </div>

                          <div class="col">
                            <div class="row">
                              <label>Type:</label>
                              <b-form-select
                                v-model="workout.data.type_workout"
                                :options="typesWorkout"
                                id="type_workout"
                                class="mb-3"
                                value-field="type"
                                text-field="type"
                                disabled-field="notEnabled"
                              ></b-form-select>
                            </div>
                            <div class="row">
                              <label>Durée workout:</label>
                              <b-form-input
                                type="number"
                                v-model.number="workout.data.duree_workout"
                                id="duree_workout"
                                placeholder="Durée de l'entrainement (min)"
                              ></b-form-input>
                            </div>
                            <div class="row">
                              <label>Kcal:</label>
                              <b-form-input
                                type="number"
                                v-model.number="workout.data.kcal_workout"
                                id="kcal_workout"
                                placeholder="Kcal Workout"
                              ></b-form-input>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr />
                    <div class="container px-0 py-2">
                      <div class="alert alert-info text-center" role="alert">
                        <i class="fas fa-magic"></i> Creator Tool
                      </div>
                      <div class="row">
                        <div class="col-3">
                          <div>
                            <b-tabs content-class="mt-3" fill>
                              <b-tab
                                title="Exercices"
                                active
                                @click="changeTypeDrag('exercices')"
                              >
                                <input
                                  type="text"
                                  class="form-control"
                                  id="searchexercice"
                                  placeholder="Search exercice..."
                                  @keyup="searchExercice()"
                                />
                                <select
                                  class="form-select mt-2"
                                  aria-label="Default select example"
                                  @change="searchExercice()"
                                  id="searchMuscle"
                                >
                                  <option value="all" selected>
                                    All muscles
                                  </option>
                                  <option
                                    v-for="muscle in muscles"
                                    :key="'musclesearch' + muscle"
                                    :value="muscle"
                                  >
                                    {{ muscle }}
                                  </option>
                                </select>
                                <div class="listExercices mt-3">
                                  <Container
                                    class="item"
                                    behaviour="copy"
                                    group-name="1"
                                    :get-child-payload="getChildPayload1"
                                  >
                                    <Draggable
                                      v-for="exercice in exercices"
                                      :key="exercice.id"
                                    >
                                      <div
                                        class="droppable-item"
                                        data-type="exercice"
                                        :data-id="exercice.id"
                                      >
                                        <div
                                          class="container boxlist px-0 py-2"
                                        >
                                          <div class="row">
                                            <div class="col-auto">
                                              <img
                                                :src="
                                                  exercice.data.photo.thumbnail
                                                "
                                                width="50px"
                                              />
                                            </div>
                                            <div class="col">
                                              <p class="fw-bold mb-0">
                                                {{
                                                  exercice.data.description.it
                                                    .nom
                                                }}
                                              </p>
                                              <div></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Draggable>
                                  </Container>
                                </div>
                              </b-tab>
                              <b-tab
                                title="Videos"
                                @click="changeTypeDrag('videos')"
                              >
                                <input
                                  type="text"
                                  class="form-control"
                                  id="searchvideo"
                                  placeholder="Search video..."
                                  @keyup="searchVideo()"
                                />
                                <div class="listVideos mt-3">
                                  <Container
                                    class="item"
                                    behaviour="copy"
                                    group-name="1"
                                    :get-child-payload="getChildPayload2"
                                  >
                                    <Draggable
                                      v-for="video in videos"
                                      :key="video.id"
                                    >
                                      <div
                                        class="droppable-item"
                                        data-type="video"
                                        :data-id="video.id"
                                      >
                                        <div
                                          class="container boxlist px-0 py-2"
                                        >
                                          <div class="row">
                                            <div class="col-auto">
                                              <img
                                                :src="
                                                  video.data.photo.thumbnail
                                                "
                                                width="50px"
                                              />
                                            </div>
                                            <div class="col">
                                              <p class="fw-bold mb-0">
                                                {{
                                                  video.data.description.it.nom
                                                }}
                                              </p>
                                              <div></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Draggable>
                                  </Container>
                                </div>
                              </b-tab>
                            </b-tabs>
                          </div>
                        </div>

                        <div class="col-9">
                          <h5>Workout:</h5>
                          <hr />
                          <div class="containerPartieWorkout">
                            <div class="groups">
                              <div
                                v-for="(partie, key) in workout.data
                                  .part_workout"
                                :key="'partieworkout' + key"
                                :id="'partieworkout' + key"
                              >
                                <div class="group">
                                  <div
                                    class="container alert alert-dark p-1 mb-1"
                                  >
                                    <div class="row">
                                      <div class="col">
                                        <div
                                          class="alert alert-light"
                                          role="alert"
                                        >
                                          <div class="float-end">
                                            <i
                                              class="fas fa-trash-alt"
                                              @dblclick="deletePartWorkout(key)"
                                            ></i>
                                          </div>
                                          Part #{{ key + 1 }}
                                          <button
                                            type="button"
                                            class="
                                              btn btn-secondary btn-sm
                                              ml-2
                                              mr-2
                                            "
                                            v-if="key != 0"
                                            @click="
                                              arraymove(
                                                workout.data.part_workout,
                                                key,
                                                key - 1
                                              )
                                            "
                                          >
                                            UP
                                          </button>
                                          <button
                                            type="button"
                                            class="btn btn-secondary btn-sm"
                                            v-if="
                                              key + 1 <
                                              workout.data.part_workout.length
                                            "
                                            @click="
                                              arraymove(
                                                workout.data.part_workout,
                                                key,
                                                key + 1
                                              )
                                            "
                                          >
                                            DOWN
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="row">
                                      <div class="col">
                                        <div
                                          class="alert alert-secondary"
                                          role="alert"
                                        >
                                          <b-tabs content-class="mt-3">
                                            <b-tab
                                              v-for="(
                                                langue, key
                                              ) in partie.description"
                                              :key="'descriptiontab-' + key"
                                              :active="key === 'it'"
                                            >
                                              <template slot="title">
                                                <img
                                                  :src="
                                                    'https://flagcdn.com/h20/' +
                                                    key +
                                                    '.png'
                                                  "
                                                  height="12"
                                                />
                                                {{ key }}
                                              </template>

                                              <div>
                                                <input
                                                  type="text"
                                                  class="form-control"
                                                  :id="'workoutnom_' + key"
                                                  v-model="
                                                    partie.description[key].nom
                                                  "
                                                />
                                              </div>
                                            </b-tab>
                                          </b-tabs>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="row">
                                      <div class="col">
                                        <b-form-select
                                          v-model="partie.type"
                                          :options="typePartWorkout"
                                          text-field="type"
                                          style="width: 100%"
                                        ></b-form-select>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="container alert alert-primary my-2"
                                    role="alert"
                                    v-if="partie.type == 'HIIT'"
                                  >
                                    <div class="row">
                                      <div class="col">
                                        <small>Giri</small><br />
                                        <b-form-input
                                          type="number"
                                          size="sm"
                                          v-model.number="
                                            partie.type_hiit.rounds_hiit
                                          "
                                          placeholder="Giri"
                                        ></b-form-input>
                                      </div>
                                      <div class="col">
                                        <small>Lavoro (sec)</small><br />
                                        <b-form-input
                                          type="number"
                                          size="sm"
                                          v-model.number="
                                            partie.type_hiit.work_time
                                          "
                                          placeholder="Lavoro (sec)"
                                        ></b-form-input>
                                      </div>
                                      <div class="col">
                                        <small>Recupero (sec)</small><br />
                                        <b-form-input
                                          type="number"
                                          size="sm"
                                          v-model.number="
                                            partie.type_hiit.rest_time_exercice
                                          "
                                          placeholder="Recupero (sec)"
                                        ></b-form-input>
                                      </div>
                                      <div class="col">
                                        <small>Recupero tra i giri (sec)</small
                                        ><br />
                                        <b-form-input
                                          type="number"
                                          size="sm"
                                          v-model.number="
                                            partie.type_hiit.rest_time_round
                                          "
                                          placeholder="Recupero tra i giri (sec)"
                                        ></b-form-input>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="container alert alert-primary my-2"
                                    role="alert"
                                    v-if="partie.type == 'Pyramidal'"
                                  >
                                    <div class="row">
                                      <div class="col">
                                        <small>Start</small><br />
                                        <b-form-input
                                          type="number"
                                          size="sm"
                                          v-model.number="
                                            partie.type_pyramidal
                                              .start_pyramidal
                                          "
                                          placeholder="Giri"
                                        ></b-form-input>
                                      </div>
                                      <div class="col">
                                        <small>Finish</small><br />
                                        <b-form-input
                                          type="number"
                                          size="sm"
                                          v-model.number="
                                            partie.type_pyramidal
                                              .finish_pyramidal
                                          "
                                          placeholder="Lavoro (sec)"
                                        ></b-form-input>
                                      </div>
                                      <div class="col">
                                        <small>One Way?</small><br />
                                        <b-form-checkbox
                                          v-model="partie.type_pyramidal.oneway"
                                          name="oneway"
                                        >
                                          Onway
                                        </b-form-checkbox>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="alert alert-primary my-2"
                                    role="alert"
                                    v-if="partie.type == 'Running'"
                                  >
                                    <div class="row">
                                      <div class="col">
                                        <small>Run/Walk</small><br />
                                        <b-form-select
                                          v-model="
                                            partie.type_running.type_effort
                                          "
                                          :options="optionsRunning"
                                          style="width: 100%"
                                        ></b-form-select>
                                      </div>
                                      <div class="col">
                                        <small>Objectif</small><br />
                                        <b-form-input
                                          type="number"
                                          size="sm"
                                          v-model.number="
                                            partie.type_running.objectif
                                          "
                                          placeholder="Objectif"
                                        ></b-form-input>
                                      </div>
                                      <div class="col">
                                        <small>Type</small><br />
                                        <b-form-select
                                          v-model="
                                            partie.type_running.type_objectif
                                          "
                                          :options="optionsTypeObjectifRunning"
                                          style="width: 100%"
                                        ></b-form-select>
                                      </div>
                                      <div class="col">
                                        <small>Vitesse (facultatif)</small
                                        ><br />
                                        <b-form-input
                                          type="number"
                                          size="sm"
                                          v-model.number="
                                            partie.type_running.objectif_vitesse
                                          "
                                          placeholder="Objectif vitesse"
                                        ></b-form-input>
                                      </div>
                                    </div>
                                  </div>
                                  <Container
                                    @drop="onDrop(key, $event)"
                                    :key="
                                      'exercicesList' + workout.id + '-' + key
                                    "
                                    v-if="partie.type != 'Running'"
                                    group-name="1"
                                  >
                                    <Draggable
                                      v-for="(
                                        exercicepart, index
                                      ) in partie.exercices"
                                      :key="'exerciceList' + index"
                                    >
                                      <div class="draggable-item">
                                        <div
                                          class="
                                            boxIngredient
                                            container
                                            align-items-center
                                          "
                                          :id="'boxExerciceWorkout' + index"
                                        >
                                          <div class="row">
                                            <div class="col-4">
                                              <async-component
                                                :key="
                                                  'exercice-' +
                                                  key +
                                                  index +
                                                  forceUpdateList
                                                "
                                                :exerciceref="
                                                  exercicepart.exercice
                                                "
                                                :exercicetype="partie.type"
                                              ></async-component>
                                            </div>

                                            <div class="col-7">
                                              <div
                                                class="row"
                                                v-if="partie.type == 'AFAP'"
                                              >
                                                <div class="col-6">
                                                  <small>rep</small><br />
                                                  <b-form-input
                                                    v-model.number="
                                                      exercicepart.repetition_exercice
                                                    "
                                                    size="sm"
                                                    placeholder="rep"
                                                  ></b-form-input>
                                                </div>
                                                <div class="col-6">
                                                  <small>type rep</small><br />
                                                  <b-form-select
                                                    v-model="
                                                      exercicepart.type_rep_exercice
                                                    "
                                                    :options="
                                                      optionsTypeRepetExo
                                                    "
                                                    size="sm"
                                                    style="width: 100%"
                                                  ></b-form-select>
                                                </div>
                                              </div>
                                              <div
                                                class="row"
                                                v-if="partie.type == 'Normal'"
                                              >
                                                <div
                                                  class="col-2 p-0"
                                                  style="
                                                    padding-right: 2px !important;
                                                  "
                                                >
                                                  <small>Series</small><br />
                                                  <b-form-input
                                                    v-model.number="
                                                      exercicepart.set_exercice
                                                    "
                                                    size="sm"
                                                    placeholder="series"
                                                  ></b-form-input>
                                                </div>
                                                <div
                                                  class="col-2 p-0"
                                                  style="
                                                    padding-right: 2px !important;
                                                  "
                                                >
                                                  <small>rep</small><br />
                                                  <b-form-input
                                                    v-model.number="
                                                      exercicepart.repetition_exercice
                                                    "
                                                    size="sm"
                                                    placeholder="rep"
                                                  ></b-form-input>
                                                </div>
                                                <div
                                                  class="col-2 p-0"
                                                  style="
                                                    padding-right: 2px !important;
                                                  "
                                                >
                                                  <small>type rep</small><br />
                                                  <b-form-select
                                                    v-model="
                                                      exercicepart.type_rep_exercice
                                                    "
                                                    :options="
                                                      optionsTypeRepetExo
                                                    "
                                                    size="sm"
                                                    style="width: 100%"
                                                  ></b-form-select>
                                                </div>
                                                <div
                                                  class="col-3 p-0"
                                                  style="
                                                    padding-right: 2px !important;
                                                  "
                                                >
                                                  <small>Rec/serie</small><br />
                                                  <b-form-input
                                                    v-model.number="
                                                      exercicepart.recuperation_set
                                                    "
                                                    size="sm"
                                                    placeholder="rec/serie"
                                                  ></b-form-input>
                                                </div>
                                                <div
                                                  class="col-3 p-0"
                                                  style="
                                                    padding-right: 2px !important;
                                                  "
                                                >
                                                  <small>Rec/Ex</small><br />
                                                  <b-form-input
                                                    v-model.number="
                                                      exercicepart.recuperation_exercice
                                                    "
                                                    size="sm"
                                                    placeholder="rec/serie"
                                                  ></b-form-input>
                                                </div>
                                              </div>
                                              <div
                                                class="row"
                                                :id="
                                                  'commentaire-' +
                                                  key +
                                                  '-' +
                                                  index
                                                "
                                                style="display: none"
                                              >
                                                <div
                                                  class="
                                                    alert alert-warning
                                                    mt-2
                                                  "
                                                  role="alert"
                                                >
                                                  <b-tabs content-class="mt-3">
                                                    <b-tab
                                                      v-for="(
                                                        langue, keycom
                                                      ) in partie.description"
                                                      :key="
                                                        'commentaireexo-' +
                                                        key +
                                                        index +
                                                        keycom
                                                      "
                                                      :active="keycom === 'it'"
                                                    >
                                                      <template slot="title">
                                                        <img
                                                          :src="
                                                            'https://flagcdn.com/h20/' +
                                                            keycom +
                                                            '.png'
                                                          "
                                                          height="12"
                                                        />
                                                        {{ keycom }}
                                                      </template>

                                                      <div>
                                                        <input
                                                          type="text"
                                                          class="form-control"
                                                          v-model="
                                                            exercicepart
                                                              .commentaires[
                                                              keycom
                                                            ]
                                                          "
                                                        />
                                                      </div>
                                                    </b-tab>
                                                  </b-tabs>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="col-1">
                                              <div class="row">
                                                <div class="col-6 p-0">
                                                  <br />
                                                  <button
                                                    type="button"
                                                    class="
                                                      btn btn-secondary btn-sm
                                                    "
                                                    @click="
                                                      showHideCommentaire(
                                                        key,
                                                        index
                                                      )
                                                    "
                                                  >
                                                    <i
                                                      class="
                                                        fas
                                                        fa-comment-medical
                                                      "
                                                    ></i>
                                                  </button>
                                                </div>
                                                <div class="col-6 p-0">
                                                  <br />
                                                  <button
                                                    type="button"
                                                    class="
                                                      btn btn-secondary btn-sm
                                                    "
                                                    @dblclick="
                                                      deleteExerciceList(
                                                        key,
                                                        index
                                                      )
                                                    "
                                                  >
                                                    <i class="fas fa-trash"></i>
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Draggable>
                                  </Container>

                                  <hr class="separator" />
                                </div>
                              </div>
                            </div>

                            <div class="d-grid gap-2 mt-4">
                              <button
                                class="btn btn-warning"
                                type="button"
                                @click="addPartWorkout()"
                              >
                                + Add part
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
